























import Vue from 'vue';
import AccountApi from '@/apis/AccountApi';

import EmailIcon from '@/assets/send-email.svg';

export default Vue.extend({
  data() {
    return {
      EmailIcon,
      registerId: '',
      emailText: 'unknown',
      isResent: false,

    };
  },
  computed: {
  },
  mounted() {
    this.registerId = this.$cookies.get('__registerId');

    if (this.registerId) {
      this.loadInfo();
    } else {
      this.$router.push('/error');
    }
  },
  methods: {
    async handleResend() {
      this.isResent = false;

      AccountApi.resend(this.registerId).then(() => {
        this.isResent = true;

        this.$notify({
          type: 'success',
          title: this.$t('已重送驗證信').toString(),
        });
      });
    },
    loadInfo() {
      AccountApi.getRegisterInfo(this.registerId).then((registerInfo) => {
        this.emailText = registerInfo.email;
      }).catch(() => {
        this.$router.push('/error');
      });
    },
  },
});
